function OurValues() {
    return ( 
        <>
            <section className="our-values">
                <div className="container">

                    <h2>Our values</h2>

                    <div className="flex-items">
                        <div className="item">
                            <div className="heading">
                                People come<br />first
                            </div>
                            <div className="data">                                
                                At the core of any tech business are the people who create the products that attract customers. We firmly believe that continuous development is key to both individual and company progress. At Rhodium, we foster a culture of learning, knowledge-sharing, and career growth. We ensure that every team member keeps moving forward and encourage initiative, innovation, and proactive thinking in both idea generation and execution. Mutual respect and honoring commitments are values we uphold across the team.
                            </div>
                        </div>

                        <div className="item">
                            <div className="heading">
                                Technology drives progress
                            </div>
                            <div className="data">
                            We believe that technology is the true driver of progress, leaving no gap between ideas and execution. We embrace anything that enhances and accelerates our work to deliver the best outcomes. We don’t cling to outdated solutions that no longer serve us. Instead, we constantly monitor the market, test new technologies, and adopt the most effective ones.
                            </div>
                        </div>

                        <div className="item">
                            <div className="heading">
                                The company's DNA is customer focus
                            </div>
                            <div className="data">
                            Quality, service, technology, and flexibility—every customer, every time! Our growth and development are thanks to our customers, who seek us out for cutting-edge solutions. The best way we can show our gratitude for your trust is by delivering high-quality, efficient solutions for your business with top-tier service.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default OurValues;