import { Link } from "react-router-dom"; 

function Expertise() {
    return ( 
        <>
            <section className="expertise">

                <div className="overlay"></div>
                
                <div className="container">

                    <h2>Our Expertise</h2>
                    
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6">
                            {/*}<div className="mt"></div>{*/}
                            <div className="expertise-card">

                                <h3>Marketing</h3>
                                
                                <div className="flex">
                                    <div className="num">/02</div>
                                    <p>Comprehensive website promotion services using the most modern tools to achieve high results. We specialize in a range of services, including SEO promotion, PPC advertising, SMM marketing and much more. Our main goal is to help brands create a successful digital presence on the Internet.</p>
                                </div>

                                <div className="btn-container">
                                    <div className="main-button">
                                        <Link className="nav-link" to="/contacts">
                                            let's begin
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="expertise-card">
                                
                                <h3>Web development</h3>
                                
                                <div className="flex">
                                    <div className="num">/01</div>
                                    <p>Building a website for your business is no longer optional—it's essential for success. Running an online operation comes with challenges, from maintaining customer interest and loyalty to managing fluctuating sales and partner relationships. A website brings agility and flexibility, allowing your business to adapt quickly to any situation.</p>
                                </div>

                                <div className="btn-container">
                                    <div className="main-button">
                                        <Link className="nav-link" to="/contacts">
                                            let's begin
                                        </Link>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        {/*}
                        <div className="col-12 col-md-12 col-lg-4">
                            <div className="mt"></div>
                            <div className="expertise-card">
                                
                                <h3>Media Production</h3>
                                
                                <div className="flex">
                                    <div className="num">/03</div>
                                    <p>Get a perfect blend of creative, production and marketing strategy. Have your product communicated with a high degree of precision, and creative tact. Across video, design and illustration, get all the creative production your company needs.</p>
                                </div>

                                <div className="btn-container">
                                    <div className="main-button">
                                        <Link className="nav-link" to="/contacts">
                                            let's begin
                                        </Link>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        {*/}
                    </div>                    
                </div>
            </section>
        </>
     );
}

export default Expertise;