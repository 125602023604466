import ContactsSection from "../components/contacts/contacts";
import Header from "../components/header";

function Contacts({updatePopup}) {
    return ( 
        <>
            <div className="inner-header">
                <Header alternativeLogo={true} />
            </div>
            <main className="main-contacts overflow-none bg-black">
                <ContactsSection updatePopup={updatePopup} />
            </main>
        </>
    );
}

export default Contacts;