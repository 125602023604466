import Footer from "../components/footer";
import Header from "../components/header";
import PrivacyNTerms from "../components/privacy&terms";

function PrivacyNTermsPage() {
    return ( 
        <>
            <Header />
                <main>
                    <PrivacyNTerms />
                </main>
            <Footer />
        </>
     );
}

export default PrivacyNTermsPage;