import axios from "axios";
import { useForm } from "react-hook-form";

function MainLastForm({updatePopup}) {

    const {register, handleSubmit} = useForm();

    let onSubmit = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('company', data.company);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('context', data.context);
    
        axios.post('/backend/email-sender-client.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(() => {
            console.log("submitted!");
            updatePopup(true);
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    };
    
    return ( 
        <>
            <section className="main-form" id="main-form">
                <div className="container">
                    <div className="form-container-wrapper">

                        <div className="row-form-contacts">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h2>Let's get started</h2>
                                
                                <div className="flex-row">
                                    <input type="text" {...register("name", {required: true})} id="name" placeholder="Full name" required/>
                                    <input type="text" {...register("company", {required: true})} id="company-name" placeholder="Company name" required/>
                                    <input type="text" {...register("email", {required: true})} id="email-address" placeholder="Email address" required/>
                                    <input type="text" {...register("phone", {required: true})} id="phone-number" placeholder="Phone number" required/>
                                </div>

                                <textarea name="context" {...register("context", {required: true})} placeholder="Write something about your project ..." id=""></textarea>

                                <button className="main-button">discuss the project</button>
                            </form>

                            <div className="contact-data">
                                <address>
                                    <a href="mailto:contact@rhodiumsites.com">contact@rhodiumsites.com</a>
                                    {/*<a href="tel:+13158732939">+1 (315) 873-2939</a>*/}
                                    <p>Warsaw, Poland</p>
                                    <p>London, United Kingdom</p>
                                    <p>Wyoming, USA</p>
                                </address>

                                <div class="earth">
                                    <div class="clouds"></div>
                                    <div class="shadow"></div>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
     );
}

export default MainLastForm;