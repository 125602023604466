function MainCareers() {
    return ( 
        <>
            <section className="main-careers">
                <div className="container">
                    <div className="wrapper">

                        <div className="text-section">
                            <h1>Build your</h1>
                            <h1 class="special">career</h1>
                            <div className="flex-row">
                                <h1>with us</h1> 
                                <p>Join one of the fastest growing teams in the industry and create great projects with us</p>
                            </div>
                        </div>

                        <div className="relative">
                            <div className="image-bg"></div>
                        </div>

                    </div>
                </div>
            </section>
        </>
     );
}

export default MainCareers;