import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import logow from "../assets/logo-w.png";
import Popup from "./popup";
import Flag from "react-flagkit";

function Header({alternativeLogo}) {
    const [menu, toggleMenu] = useState(false);

    return ( 
        <>
            <header>                
                <nav class="navbar navbar-expand-lg">
                    <div className="container">

                        <a class="navbar-brand" href="./" className="logo-img">
                            <img src={logow} />
                        </a>
                        
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => toggleMenu(!menu)}>
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <ul className="flex-center nav hide-900">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">MAIN</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/works">FINISHED PROJECTS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">ABOUT</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/careers">CAREERS</Link>
                                </li>
                        </ul>

                        <div className="flex-end flex-modified hide-900" id="navbarSupportedContent">

                            <button className="main-button">
                                <Link className="nav-link" to="/contacts">
                                    <span>contact us</span>
                                </Link>
                            </button>                            

                            <div className="flag-n-number">
                                <Flag country="US" />
                                {/*<a href="tel:+13158732939">+1 (315) 873-2939</a>*/}
                            </div>
                        </div>

                    </div>
                </nav>
                { menu && (
                    <div className="container mobile-nav">
                        <ul className="nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">MAIN</Link>
                            </li>
                            <li className="nav-item active">
                                <Link className="nav-link" to="/works">FINISHED PROJECTS</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">ABOUT</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/careers">CAREERS</Link>
                            </li>
                        </ul>

                        <button className="main-button">
                            <Link className="nav-link" to="/contacts">
                                <span>contact us</span>
                            </Link>
                        </button>                            

                    </div>
                )}
            </header>
        </>
     );
}

export default Header;