import avatar1 from "../../assets/avatars/avatar-1.gif"
import avatar2 from "../../assets/avatars/avatar-2.gif"
import avatar3 from "../../assets/avatars/avatar-3.gif"
import avatar4 from "../../assets/avatars/avatar-4.gif"
import avatar5 from "../../assets/avatars/avatar-5.gif"

function Team() {
    return ( 
        <>
            <section className="team">
                
                <div className="overlay"></div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <h1>The team you need to succeed</h1>
                            <p>Fueled by passion and a commitment to our clients, our team of over 20 digital experts drives performance for small businesses and Fortune 500 companies alike.</p>
                            <div className="main-button">
                                <a href="/contacts">
                                    DISCUSS PROJECT
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="image-container">
                                <img src={avatar1} alt="" />
                                <img src={avatar2} alt="" />
                                <img src={avatar3} alt="" />
                                <img src={avatar4} alt="" />
                                <img src={avatar5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </> 
    );
}

export default Team;