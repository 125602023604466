import banner from "../../assets/rh-banner.png";

function WhyUs() {
    return ( 
        <>
            <section className="why-us">
                <div className="container">
                    <h2>Working to build <br />your success</h2>

                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-5">
                            <div className="elements">
                                
                                <div className="element">
                                    <h1>20+</h1>
                                    <p>Highly qualified<br/>fulltime professionals</p>
                                </div>

                                <div className="element">
                                    <h1>&</h1>
                                    <p>Flexibility and<br/>adaptability</p>
                                </div>

                                <div className="element">
                                    <h1>&lt;/&gt;</h1>
                                    <p>Analytics<br/>and news</p>
                                </div>

                                <div className="element">
                                    <h1>*</h1>
                                    <p>SEO<br/>optimization</p>
                                </div>

                                <div className="element blacked">
                                    <h1 className="color-main">∞</h1>
                                    <p>Data<br/>security</p>
                                </div>

                                <div className="element blacked">
                                    <h1 className="color-main">~</h1>
                                    <p>Personalized<br/>design</p>
                                </div>

                            </div>
                        </div>

                        <div className="col-12 col-md-12 col-lg-7 adjusted">
                            <div className="image-about">
                                <img src={banner}/>
                            </div>
                            <div className="text-container">
                                <p>
                                    <b>For over 8 years</b> our team has empowered businesses to realize their vision through cutting-edge web development and digital marketing. Our mission is to craft tailored online solutions that drive success, because your vision is our priority.
                                </p>
                                <button className="main-button">
                                    more about us
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
     );
}

export default WhyUs;