import Clients from "../components/main/clients";
import Expertise from "../components/main/expertise";
import Footer from "../components/footer";
import Header from "../components/header";
import MainLastForm from "../components/main-last-form";
import MainScreenMainPage from "../components/main/main-screen-main-page";
import Steps from "../components/main/steps";
import Technologies from "../components/main/technologies";
import WhyUs from "../components/main/why-us";
import Team from "../components/main/team";
import About from "../components/main/about";

function Main({updatePopup}) {
    return ( 
        <>
            <Header />

            <main>
                <MainScreenMainPage />
                <Expertise />
                <WhyUs />
                {/*}<About />{*/}
                <Clients />
                <Technologies />
                <Team />
                <Steps />
                <MainLastForm updatePopup={updatePopup} />
            </main>

            <Footer />
        </>
     );
}

export default Main;