function FormInfo() {
    return ( 
        <>
            <section className="nda">
                <div className="line-outline">
                    we are unable to use existing names due to <b>NDA restrictions</b>
                </div>
            </section>
            <section className="form-n-info">
                <div className="container">
                    <h1>Want to see more?</h1>
                    <h2>
                        Contact us today! and we will be happy to show you our projects that align with your specific industry!
                    </h2>
                </div>
            </section>
        </>
     );
}

export default FormInfo;