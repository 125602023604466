function AboutUsMain() {
    return ( 
        <>
            <section className="about-us-main main-block">
                <div className="overlay"></div>
                <div className="container">
                
                        <div className="heading-space">
                            <div className="heading">
                                <h1>Driven by<br/>Digital Excellence</h1>
                            </div>

                            <div className="sub-information">
                                <p>
                                    - We love what we do -
                                </p>
                            </div>
                            
                            <button className="main-button">
                                <span>
                                    <a className="nav-link" href="#footer">
                                        discuss project
                                    </a>
                                </span>
                            </button>
                        </div>
                </div>
            </section>
        </>
     );
}

export default AboutUsMain;