import t1 from "../../assets/technologies/t1.png";
import t2 from "../../assets/technologies/t2.png";
import t3 from "../../assets/technologies/t3.png";
import t4 from "../../assets/technologies/t4.png";
import t5 from "../../assets/technologies/t5.png";
import t6 from "../../assets/technologies/t6.png";
import t7 from "../../assets/technologies/t7.png";
import t8 from "../../assets/technologies/t8.png";
import t9 from "../../assets/technologies/t9.png";
import t10 from "../../assets/technologies/t10.png";
import t11 from "../../assets/technologies/t11.png";


function Technologies() {
    return ( 
        <>
        
            <section className="techologies">
                <div className="container">
                    <div className="blacked-wrapper">
                        <h3>Technologies and platforms we work with</h3>

                        <div className="flex item-rows">
                            <div className="item-collection">
                                
                                <h3>Backend</h3>
                                
                                <div className="flex-items">
                                    <div className="item">
                                        <img src={t1} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t2} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t3} alt="" />
                                    </div>
                                </div>

                            </div>

                            <div className="item-collection">
                                
                                <h3>Dev Ops and Clouds</h3>
                                
                                <div className="flex-items">
                                    <div className="item">
                                        <img src={t4} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t5} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t6} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t7} alt="" />
                                    </div>
                                </div>

                            </div>


                            <div className="item-collection">
                                
                                <h3>Frontend</h3>
                                
                                <div className="flex-items">
                                    <div className="item">
                                        <img src={t8} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t9} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t10} alt="" />
                                    </div>

                                    <div className="item">
                                        <img src={t11} alt="" />
                                    </div>
                                </div>

                            </div>

                            <div className="item-collection">
                                
                                <h3>Mobile</h3>
                                
                                <div className="flex-items">
                                    <div className="item">
                                        <img src={t8} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
     );
}

export default Technologies;