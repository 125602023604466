import { CheckCircleFill } from "react-bootstrap-icons";
function PopupHR({display, update}) {
    return ( 
        <>
            {display && (
                <section className="popup">
                    <div className="popup-overlay" onClick={() => update(false)}></div>
                    <div className="popup-block">
                        <div className="popup-icon">
                            <CheckCircleFill />
                        </div>
                        <h1>Thank you for your application!</h1>
                        <p>Our recruitment team will take a look at it!</p>
                    </div>
                </section>
            )}
        </>
     );
}

export default PopupHR;