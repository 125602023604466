import axios from "axios";
import { useForm } from "react-hook-form";

function FormCareers({updatePopup}) {

    const {register, handleSubmit} = useForm();

    let onSubmit = (data) => {
        const formData = new FormData();
        
        // Append form fields
        formData.append('name', data.name);
        formData.append('position', data.position);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('context', data.context);
        
        // Append the file
        formData.append('file', data.file[0]); // Assuming `file` is a file input
    
        axios.post('/backend/email-sender-hr.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(() => {
            console.log("submitted!");
            updatePopup(true);
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    };
    

    return ( 
        <>
            <section className="form-careers">
                <div className="line-outline">
                    <b>Become one of us </b> and help us towards excellence!
                </div>

                <div className="form">
                    <div className="container">
                        <div className="row-form-contacts">
                            <div className="contact-data">
                                <h2>Not just another job, but an amazing career!</h2>
                                <p>By clicking the “Submit” button, you consent to the processing of personal data.</p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                
                                <div className="flex-row">
                                    <input type="text" {...register("name", {required: true})}  id="name" placeholder="Full name" required/>

                                    <select {...register("position", {required: true})}  id="" required>
                                        <option value="front-end">Front-end specialist</option>
                                        <option value="marketing">Marketing specialist</option>
                                        <option value="back-end">Back-end engenier</option>
                                        <option value="sales-agent">Sales Agent</option>
                                        <option value="project-leader">Project Leader</option>
                                    </select>

                                    <input type="text" {...register("email", {required: true})}  id="email-address" placeholder="Email address" required/>
                                    <input type="text"{...register("phone", {required: true})} id="phone-number" placeholder="Phone number" required/>
                                </div>

                                <textarea {...register("context", {required: true})}  placeholder="Write something about you ..." id=""></textarea>

                                <div className="flex-row">
                                    <label htmlFor="file-upload" id="file-upload-button">upload CV</label>
                                    <input type="file" className="file-upload" id="file-upload" {...register("file", {required: true})} required/>
                                    <button className="main-button">send application</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default FormCareers;