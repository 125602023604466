import building from "../../assets/building.png";

function Industries() {
    return ( 
        <>
            <section className="industries">
                <div className="container">
                    <div className="industries-wrapper">
                        <div className="flex-items">
                            <div className="left-bg-image">
                                <img src={building} alt="" />
                            </div>
                            <div className="description">
                                <h2>Industries Served</h2>

                                <ul className="industries-name">
                                    <li><span className="main-color">/</span>Manufacturing & Distribution</li>
                                    <li><span className="main-color">/</span>Professional Services</li>
                                    <li><span className="main-color">/</span>Retail & Consumer Goods</li>
                                    <li><span className="main-color">/</span>Sports & Entertainment</li>
                                    <li><span className="main-color">/</span>Transportation</li>
                                    <li><span className="main-color">/</span>Associations & Nonprofits</li>
                                    <li><span className="main-color">/</span>E-commerce</li>
                                    <li><span className="main-color">/</span>Education</li>
                                    <li><span className="main-color">/</span>Financial Services</li>
                                    <li><span className="main-color">/</span>Healthcare</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default Industries;