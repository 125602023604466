function Numbers() {
    return ( 
        <>
            <section className="numbers">
                <div className="container">
                    <h2>Company statistics</h2>

                    <div className="flex-items">
                        <div className="item">
                            <div className="item-header">
                                20+
                            </div>
                            <div className="item-content">
                            employees<br />globally
                            </div>
                        </div>

                        <div className="item">
                            <div className="item-header main-color">
                                53+
                            </div>
                            <div className="item-content">
                                Projects done<br />since start
                            </div>
                        </div>

                        <div className="item">
                            <div className="item-header">
                                4.9
                            </div>
                            <div className="item-content">
                                Customer<br />satisfaction rating
                            </div>
                        </div>

                        <div className="item">
                            <div className="item-header">
                                8+
                            </div>
                            <div className="item-content">
                                Years of<br />experience
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default Numbers;