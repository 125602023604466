function Tocareers() {
    return ( 
        <>
            <section className="to-careers">
                <div className="overlay"></div>

                <div className="container">
                    <div className="heading-space">
                        <div className="heading">
                            <h1>Jump-start your career<br/>with Rhodium sites</h1>
                        </div>

                        <div className="sub-information">
                            <p>
                            If you're passionate about internet marketing, web design, or web development, we want to hear from you!
                            </p>
                        </div>
                        
                        <button className="main-button">
                            <span>
                                <a className="nav-link" href="/careers">
                                    join team
                                </a>
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </>
     );
}

export default Tocareers;