import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import { useState } from 'react';
import Main from './pages/Main';
import Careers from './pages/Careers';
import Contacts from './pages/Contacts';
import About from './pages/About';
import Works from './pages/Works';
import Popup from './components/popup';
import PopupHR from './components/popup-hr';
import PrivacyNTermsPage from './pages/PrivacyNTerms';

function App() {
    const [popup, displayPopup] = useState(false);
    const [popupHR, displayPopupHR] = useState(false);

    return ( 
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Main updatePopup={displayPopup} />}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/careers' element={<Careers updatePopup={displayPopupHR} />}/>
                    <Route path='/contacts' element={<Contacts updatePopup={displayPopup} />}/>
                    <Route path='/works' element={<Works updatePopup={displayPopup} />}/>
                    <Route path='/privacy-n-terms' element={<PrivacyNTermsPage />}/>
                </Routes>
            </BrowserRouter>

            <Popup display={popup} update={displayPopup} />
            <PopupHR display={popupHR} update={displayPopupHR} />
        </>
     );
}

export default App;