import Footer from "../components/footer";
import Header from "../components/header";

import MainLastForm from "../components/main-last-form";
import CasesMain from "../components/works/cases-main";
import CasesTemplate from "../components/works/cases-template";
import FormInfo from "../components/works/form-info";

function Works({updatePopup}) {

    return ( 
        <>
            <Header />
            <main>
                <CasesMain />
                <CasesTemplate />
                <FormInfo />
                <MainLastForm updatePopup={updatePopup} />
            </main>
            <Footer />
        </>
     );
}

export default Works;