import { Link } from "react-router-dom";
import websitePromo from "../../assets/video.mp4";
import websitePromoMobile from "../../assets/video.gif";
import achieve from "../../assets/achieve.png";

function MainScreenMainPage() {
    return ( 
        <>
            <section className="main-block">
                <div className="container">
                    <div className="leftpart">
                        <div className="heading"><h1>Web development</h1><span className="line"></span></div>
                        <div className="heading"><span className="line"></span><h1>And</h1></div>
                        <div className="heading"><h1>Marketing</h1><span className="line"></span></div>

                        <div className="sub-information">
                            <p>
                                E-commerce Platform, Real Estate Website, Medical Appointment Booking System, Marketplace or Bussines Website. <b>You name it and we build it!</b>
                            </p>
                        </div>

                        <button className="main-button">
                            <span>
                                <a className="nav-link" href="#main-form">
                                    discuss the project
                                </a>
                            </span>
                        </button>
                    </div>
                    <div className="right-part">
                        <div className="image-container">

                        <video autoPlay loop="loop" muted defaultMuted playsinline oncontextmenu="return false;"  preload="auto" id="myVideo">
                            <source src={websitePromo} type="video/mp4" />
                        </video>

                        <img src={websitePromoMobile} id="video-mobile-gif" alt="" />
                        {/*<img src="achieve" alt="" />*/}

                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default MainScreenMainPage;