import { CheckCircleFill } from "react-bootstrap-icons";
function Popup({display, update}) {
    return ( 
        <>
            {display && (
                <section className="popup">
                    <div className="popup-overlay" onClick={() => update(false)}></div>
                    <div className="popup-block">
                        <div className="popup-icon">
                            <CheckCircleFill />
                        </div>
                        <h1>Thank you! It would be a pleasure to work with you!</h1>
                        <p>Our managers will contact you as soon as possible!</p>
                    </div>
                </section>
            )}
        </>
     );
}

export default Popup;