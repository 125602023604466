import CareerTabs from "../components/careers/career-tabs";
import FormCareers from "../components/careers/form-careers";
import MainCareers from "../components/careers/main-careers";
import Footer from "../components/footer";
import Header from "../components/header";

function Careers({updatePopup}) {
    return ( 
        <>
            <Header />
                <main>

                    <MainCareers />
                    <CareerTabs />
                    <FormCareers updatePopup={updatePopup} />
    
                </main>
            <Footer />
        </>
     );
}

export default Careers;