import { Link } from "react-router-dom";
import logo from "../assets/logo-w.png";

function Footer() {
    return ( 
        <>
            <footer>
                <div className="footer-main" id="footer">
                    <div className="container">
                        <div className="flex">
                            <a class="navbar-brand" href="./" className="logo-img"><img src={logo} /></a>
                            <div className="near-text">From your Vision to <br /> a finished Product!</div>
                        </div>
                        <ul className="footer-nav nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">MAIN</Link>
                            </li>
                            <li className="nav-item active">
                                <Link className="nav-link" to="/works">FINISHED PROJECTS</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">ABOUT</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/careers">CAREERS</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-post">
                    <span>2016 ⓒ ALL RIGHTS RESERVED</span>
                    <span>IT COMPANY RHODIUM SITES | OWNED BY ILILLC LLC</span>
                    <span><a href="/privacy-n-terms" target="_blank">PRIVACY POLICY & TERMS OF CONDITIONS</a></span>
                </div>
            </footer>
        </>
     );
}

export default Footer;