import gtile from "../../assets/google-tile.png";
import atile from "../../assets/awwwards-tile.png";
import ctile from "../../assets/clutch-tile.png";

function Reputation() {
    return ( 
        <>  
            <section style={{marginTop: 50 + "px"}}></section>
        {/*
            <section className="reputation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-s-12">
                            <h2 className="heading">
                                Our reputation<br />is important
                            </h2>
                        </div>
                        <div className="col-md-7 col-s-12">
                            <div className="images-row">
                                <div className="tile image-1">
                                    <img src={gtile} alt="" />
                                </div>
                                <div className="tile image-2">
                                    <img src={atile} alt="" />
                                </div>
                                <div className="tile image-3">
                                    <img src={ctile} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        */}
        </>
     );
}

export default Reputation;