function Steps() {
    return ( 
        <>
            <section className="steps">
                <div className="container">
                    <h2> 
                        It's Easy with Us —<br />Experience the Difference
                    </h2>

                    <div className="row">
                        
                        <div className="col-12 col-md-4 section-content">
                            <div className="top-step">step 1</div>
                            <h3>Strategy Development</h3>
                            <p>
                            Our dedicated team will work closely with you to thoroughly understand your objectives and create a customized strategy designed specifically to address your unique needs and goals.
                            </p>
                        </div>

                        <div className="col-12 col-md-4 section-content">
                            <div className="top-step">step 2</div>
                            <h3>Design and Development</h3>
                            <p>
                            We’ll bring your strategy to life with bespoke web design and development, ensuring that your site is not only visually compelling but also functional and user-friendly. Our approach integrates marketing elements to ensure a seamless user experience.
                            </p>
                        </div>

                        <div className="col-12 col-md-4 section-content">
                            <div className="top-step">step 3</div>
                            <h3>Marketing and Optimization</h3>
                            <p>
                            Once your site is live, we’ll implement targeted marketing campaigns and ongoing optimization to drive traffic and maximize performance. Our focus is on delivering measurable results and continuously improving your online presence.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
     );
}

export default Steps;