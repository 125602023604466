import c11 from "../../assets/cases/1.png";
import c12 from "../../assets/cases/2.png";
import c13 from "../../assets/cases/3.png";

import c21 from "../../assets/cases/4.png";
import c22 from "../../assets/cases/5.png";
import c23 from "../../assets/cases/6.png";

import c31 from "../../assets/cases/7.png";
import c32 from "../../assets/cases/8.png";
import c33 from "../../assets/cases/9.png";

import c41 from "../../assets/cases/10.png";
import c42 from "../../assets/cases/11.png";
import c43 from "../../assets/cases/12.png";

import c51 from "../../assets/cases/13.png";
import c52 from "../../assets/cases/14.png";
import c53 from "../../assets/cases/15.png";

import c61 from "../../assets/cases/16.png";
import c62 from "../../assets/cases/17.png";
import c63 from "../../assets/cases/18.png";

import c71 from "../../assets/cases/19.png";
import c72 from "../../assets/cases/20.png";
import c73 from "../../assets/cases/21.png";

import styles from "../../scss/index.scss";

function CasesTemplate() {

    let cases = [
        {
            mainImage: c71,
            naming: "Delight spices",
            shortDesc: "The client approached us with the idea of ​​creating a modern and convenient online culinary platgormstore of premium spices combining a recipe system with the ability to purchase ingredients directly from the site.",
            image1: c72,
            image2: c73,
            subText: "We created a comprehensive eCommerce system for the food industry from scratch",
            subPoints: [
                "Developed a web store and a cross-platform mobile application on a single backend;",
                "Implemented a store admin panel with CRM functionality;",
                "Created and integrated recipe system with eCommerce"
            ]
        },{
            mainImage: c41,
            naming: "Pet care service",
            shortDesc: "Petstory is a universal digital information center for pet parents. On the website, you can get advice from doctors of various specialties, and it is also possible to arrange insurance through the built-in service. Each client has their own personal account where they can find the dates of birth of animals, vaccination dates, consultation history, consultation reports and promotions",
            image1: c42,
            image2: c43,
            subText: "Development of a website, personal account, integration of insurance service",
            subPoints: [
                "Development of a web platform and integration of all the necessary services;",
                "Creation of an administration panel and a personal doctor's profile. In the profile, the doctor sees his schedule and can connect to a call in one click;",
                "Creating a personal user account. In the personal account we store the owner's profile and the profiles of all his pets."
            ]
        },{
            mainImage: c21,
            naming: "International energy holding",
            shortDesc: "Custom CRM module for an energy company. Developed a platform for managing sales and customer support. Our client's business is a group of 17 companies in the field of natural gas and electricity distribution. The client approached us with a request to create a CRM for sales and marketing specialists. The new system was supposed to replace all software packages used in the holding and become part of the new corporate ERP. In addition, the client planned to turn the product into a SaaS service in the future.",
            image1: c22,
            image2: c23,
            subText: "We created a comprehensive SaaS CRM platform that can cover all of the client's business processes",
            subPoints: [
                "Custom-develop a CRM web platform with all the necessary functionality;",
                "Provide the ability to customize the system in accordance with the client’s needs, create a user-friendly interface according to modern UI/UX standards;",
                "Bring the product into compliance with GDPR and NIST security standards, and build in the potential for development as a SaaS service."
            ]
        },{
            mainImage: c31,
            naming: "WT GROUP",
            shortDesc: "The WT Group team has been helping its clients find residential and investment properties around the world for almost 10 years.Today, the company is recognized as one of the leading international real estate agencies in Kazakhstan. Its partners include developers such as Berkeley Group, Aldar, Ellington Properties and many other development giants. Such a status requires a corresponding presence in digital. The company needed a new solution that could properly present the team's reputation and merits to potential buyers and investors. At the same time, WT Group managers needed convenient digital tools.",
            image1: c32,
            image2: c33,
            subText: "Created a new corporate web platform with informative and user-friendly UX",
            subPoints: [
                "Develop a web platform for a real estate agency with custom functionality and easy-to-read design;",
                "Create an admin panel that would solve the problems of integration with CRM and help automate routine;",
                "Integrate the platform with existing client and real estate databases."
            ]
        },{
            mainImage: c61,
            naming: "MY THERAPY",
            shortDesc: "An online service that helps you find the right psychotherapist and improve your mental well-being without leaving your home. The service includes a chat with a psychotherapist, notes, a library where you can find all the materials from the psychotherapist, such as literature or homework, a calendar of classes on the platform through which you can book, reschedule or cancel a session, a progress Scale with client goals and much more.",
            image1: c62,
            image2: c63,
            subText: "Create a space where you can find your psychotherapist and practice from anywhere",
            subPoints: [
                "Web application design, concept and development",
                "Mobile application design, mobile application development;",
                "Release and support."
            ]
        },{
            mainImage: c51,
            naming: "Global B2C Marketplace",
            shortDesc: "Our customer is a US company wanting to unite buyers and merchants in a digital environment. Despite the vast experience in e-commerce, the customer decided not to build a web marketplace on its own and turn to experts. The company needed a technical partner to develop a custom solution and define the proper MVP to increase the ROI.",
            image1: c52,
            image2: c53,
            subText: "A web software solution and a cross-platform mobile application for a marketplace working in the US and European area",
            subPoints: [
                "Buyer module. The buyer module lets guests and authorized users browse and search for goods, while authorized buyers can manage profiles, make purchases, chat with support, and save their order history. The search module, powered by Elasticsearch, offers high-performance search with suggestions and autocomplete. This functionality is also available on the mobile app for iOS and Android.",
                "Shop module. The shop module allows business users to manage branding, listings, orders, payments, and add extra users after verification and approval by the Marketplace Administration. It supports flexible pricing management, multi-currency listings for Europe and the US, and includes order handling with transactions, returns, and refunds. Shop owners can also post ads, announcements, and manage sales campaigns.",
                "Inventory module. The inventory module enables merchants to manage their shop's listings, quantities, availability, pre-orders, and flexible descriptions. An AI-based content moderation ensures 18+ texts and images are flagged, protecting users from adult content. Merchants can also post banners, announcements, and manage buyer details, including purchase history and bookmarks, after verification by the marketplace administration.",
                'The buying pipeline covers the entire process from product selection to checkout, including discounts, payment, and returns. It supports multiple payment providers with 3D Secure transactions for EU and US users. Shops can set up individual or category-wide discounts, and users can manage orders, delivery, and payment options efficiently.'
            ]
        }
    ]

    let mappedCases = cases.map((val, ind, arr) => {

        let points = "";

        for(let i=0; i<val.subPoints.length; i++){
            points += `
                <div className="${styles.subPoints}">
                    ${val.subPoints[i]}
                </div>`;
        }

        return (
            <div className="case">
                
                <div className="main-case-image">
                    <img src={val.mainImage} alt="" />
                </div>

                <div className="main-case-text">
                    <div className="flex-items">
                        <div className="name-text">
                            {val.naming}
                        </div>
                        <div className="short-desc">
                            {val.shortDesc}
                        </div>
                    </div>
                </div>
                
                <div className="main-case-subimages">
                    <div className="flex-items">
                        <div className="image-1">
                            <img src={val.image1} alt="" />
                        </div>
                        <div className="image-2">
                            <img src={val.image2} alt="" />
                        </div>
                    </div>
                </div>
                
                <div className="main-case-subtext">
                    <div className="flex-items">
                        <div className="sub-text">
                            {val.subText}
                        </div>

                        <div className="sub" dangerouslySetInnerHTML={{ __html: points }} />

                    </div>
                </div>

            </div>
        );
    });

    return ( 
        <>
            <section className="cases">
                <div className="container">
                    {mappedCases}
                </div>
            </section>
        </>
     );
}

export default CasesTemplate;