import { useEffect, useState } from "react";
import Slider from 'react-infinite-logo-slider'

import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import c6 from "../../assets/c6.png";
import c7 from "../../assets/c7.png";
import c9 from "../../assets/c9.png";

const logos = [
    { id: 1, src: c1, alt: 'Logo 1' },
    { id: 2, src: c2, alt: 'Logo 2' },
    { id: 3, src: c3, alt: 'Logo 3' },
    { id: 4, src: c5, alt: 'Logo 4' },
    { id: 5, src: c4, alt: 'Logo 5' },
    { id: 6, src: c7, alt: 'Logo 6' },
    { id: 7, src: c6, alt: 'Logo 7' },
    { id: 8, src: c9, alt: 'Logo 8' },
  ];

function Clients() {
    
    let mappedLogos = logos.map((logo) => {
        return(
            <Slider.Slide>
                <img src={logo.src} alt={logo.alt} className='logo-slider-item' />
            </Slider.Slide>
        );
    })

    return ( 
        <>

            <section className="clients">
                <div className="container">

                    <h2>Our clients</h2>

                    <div className="slider-container">
                        <Slider width="250px" duration={40} pauseOnHover={true} blurBorders={false}>
                            {mappedLogos}
                        </Slider>
                    </div>

                    {/*}
                    <div class="container-grid">
                        <div class="item"><img src={c1} alt="Image 1" /></div>
                        <div class="item"><img src={c2} alt="Image 2" /></div>
                        <div class="item"><img src={c3} alt="Image 3" /></div>
                        <div class="item"><img src={c4} alt="Image 4" /></div>
                        <div class="item"><img src={c5} alt="Image 5" /></div>
                        <div class="item"><img src={c6} alt="Image 6" /></div>
                        <div class="item"><img src={c7} alt="Image 7" /></div>
                        <div class="item"><img src={c8} alt="Image 8" /></div>
                    </div>
                    {*/}

                </div>
            </section>
        </>
     );
}

export default Clients;