import Footer from "../components/footer";
import Header from "../components/header";

import OurValues from "../components/about/our-values";
import Industries from "../components/about/industries";
import Numbers from "../components/about/numbers";
import AboutUsMain from "../components/about/about-us-main";
import Reputation from "../components/about/reputation";
import Tocareers from "../components/about/to-careers";

function About() {
    return ( 
        <>
            <Header />
            
            <main>
                <AboutUsMain />
                <Industries />
                <Numbers />
                <OurValues />
                <Reputation />
                <Tocareers />
            </main>

            <Footer />
        </>
     );
}

export default About;