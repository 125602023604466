import whatsapp from "../../assets/whatsapp.png"
import { useForm } from "react-hook-form";
import axios from "axios";

function ContactsSection({updatePopup}) {
    
    const {register, handleSubmit} = useForm();

    let onSubmit = (data) => {
        axios.post('/backend/email-sender-client.php', 
            {
                name: data.name,
                email: data.email,
                context: data.context
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        ).then(() => {
            console.log("submitted!");
            updatePopup(true);
        });
    };

    return ( 
        <>
            <section className="contacts">
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="item">
                                <h1>
                                    Let's make<br />
                                    your <span className="main-color">VISION</span><br />come true
                                </h1>
                                <p>
                                We’ll contact you as soon as possible to schedule a online meeting to discuss your goals and vision.
                                </p>
                                <div className="project-data">
                                    <div className="row short-company-info">
                                        <div className="col-7 col-md-6">
                                            <div className="contacts-header">PROJECT INQUIRIES</div>
                                            <address>
                                                <a href="mailto:contact@rhodiumsites.com">contact@rhodiumsites.com</a>
                                            </address>
                                        </div>
                                        <div className="col-5 col-md-6">
                                            <div className="contacts-header">OFFICES</div>
                                            <address>
                                                Warsaw, Poland <br/>
                                                London, United Kingdom <br/>
                                                Wyoming, USA
                                            </address>
                                            {/*
                                            <address>
                                                <a href="tel:+13158732939">+1 (315) 873-2939</a>
                                            </address>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="top-form">

                                {/*}
                                <div className="top-form-row">
                                    <div className="contacts-header">CONTACT US</div>
                                    <div className="sub-text">on</div>
                                    <div className="second-button"><img src={whatsapp} className="whatsapp"/>Whatsapp</div>
                                </div>
                                {*/}

                                <div className="another-flex">
                                    <p className=""><b>CONTACT US</b></p>
                                    <span className="line"></span>
                                </div>

                                <div className="form">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="flex-data">
                                            <input type="text" {...register("name", {required: true})} id="name" placeholder="Full name" required/>
                                            <input type="text" {...register("email", {required: true})} id="email-address" placeholder="Email address" required/>
                                        </div>

                                        <textarea {...register("context", {required: true})} placeholder="Write something about your project ..." id="" required></textarea>

                                        <button className="main-button">
                                            contact us
                                        </button>

                                    </form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
     );
}

export default ContactsSection;