import Accordion from 'react-bootstrap/Accordion';

function CareerTabs() {
    return ( 
        <>
            <section className="career-tabs">
                <div className="container">
                    <div className="tabs-block">
                        
                        <h1>Current vacancies</h1>


                        <Accordion defaultActiveKey="2">

                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <div className="flex">
                                        <div className="heading">
                                            <div className="flex">
                                                <div>Front-end specialist</div>
                                                <div className="locale">
                                                    Remote
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            Read More
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                We are looking for a Front-end Specialist with proficient knowledge of HTML, CSS, and JavaScript (ES6+). The ideal candidate should have at least 1 year of experience in front-end development and possess good knowledge of React and Redux. Familiarity with responsive design principles and basic understanding of version control systems like Git are required. The ability to work with REST APIs and attention to detail are also important qualities for this role.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    <div className="flex">
                                        <div className="heading">
                                            <div className="flex">
                                                <div>Marketing specialist</div>
                                                <div className="locale">
                                                    Remote
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            Read More
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                We are looking for a Marketing Manager with expertise in managing targeted ads on platforms such as Facebook, Instagram, and TikTok. The ideal candidate should have strong skills in social media management, including content creation, audience engagement, and performance analysis. A solid understanding of marketing strategies and analytics tools is essential to drive successful campaigns and achieve business goals.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    <div className="flex">
                                        <div className="heading">
                                            <div className="flex">
                                                <div>BACK-END ENGINEER</div>
                                                <div className="locale">
                                                    Remote
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            Read More
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                We are seeking a Senior Back-end Engineer with over 5 years of experience and expertise in Node.js or Python. The ideal candidate should have extensive experience in developing scalable applications and a deep understanding of popular libraries and frameworks, such as Express.js for Node.js or Django and Flask for Python. Strong knowledge of database systems (SQL and NoSQL), RESTful API design, and microservices architecture is essential. Experience with cloud services like AWS or Azure and familiarity with version control systems, such as Git, is also required. Excellent problem-solving skills and the ability to work collaboratively in a team environment are key attributes for this role.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                    <div className="flex">
                                        <div className="heading">
                                            <div className="flex">
                                                <div>Sales AGENT</div>
                                                <div className="locale">
                                                    Remote
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            Read More
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                We're looking for a Sales Agent with a minimum of 3+ years of experience in the web development and marketing sector. The ideal candidate should have strong knowledge of digital marketing services, web development processes, and a proven ability to meet sales targets. Excellent communication, relationship-building, and negotiation skills are essential, along with the capability to explain technical concepts to non-technical clients and work independently to drive sales growth.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header>
                                    <div className="flex">
                                        <div className="heading">
                                            <div className="flex">
                                                <div>Project Leader</div>
                                                <div className="locale">
                                                    Remote
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            Read More
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                We're looking for a Project Leader with a minimum of 5+ years of experience in the web development and marketing sector. The ideal candidate should have a strong ability to lead and manage multiple projects while ensuring timely delivery with an in-depth understanding of web development processes and digital marketing strategies. Excellent communication skills are required to work closely with clients and internal teams. Proficiency in project management tools like Jira, Trello or Asana is essential along with experience in Agile or Scrum methodologies.
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>


                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerTabs;